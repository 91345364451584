.tbl-img img {
    width: 50px;
    height: 50px;
}

.main-cus {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    width: 500px;
    font-family: 'Roboto', sans-serif;
    line-height: 1.1rem;
    margin: auto;
    padding: 20px 10px 0px 10px;
}

.main-card {
    display: flex;
}

.nfssx {
    font-size: 20px;
    font-weight: bold;
    color: Red;
    margin-bottom: 5px;
}

.full-form {
    font-size: 18px;
    font-weight: 500;
    color: Red;
    margin-bottom: 8px;
}

.state {
    font-size: 11px;
    font-weight: 700;
    margin-bottom: 5px;

}

.idcard-text p {
    background-color: red;
    color: #fff;
    display: inline;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: bold;
}

.gov {
    font-size: 10px;
    font-weight: 500;
}

.logo-div img {
    width: 90px;
    height: 90px;
}

.icard-details {
    margin-top: 15px;
    display: flex;
}

.user-pic img {
    width: 90px;
    height: 120px;
    border: 1px solid gray;
}

.idcard-cus-table {
    margin-left: 20px;
}

.idcard-cus-table table tbody tr td {
    font-size: 11px;
    padding: 1.5px 10px;
    border-bottom: 0;
    background-color: #fff;
    text-transform: uppercase
}