.loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.89);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 8000000;
}

.loading p {
    font-size: 3em;
    animation: anim 1s infinite;
}

@keyframes anim {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}