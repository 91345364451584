/* //pagination css */

.pagination_nav {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0px;
    width: 100%;

}

.page_item_cus .page_link_nav {
    width: 30px;
    height: 30px;
    display: flex;
    font-size: 12px;
    justify-content: center;
    align-items: center;
    background-color: #817f11;
    color: #fff;
    border-radius: 50px;
    border: 0;
    margin: 5px;
    box-shadow: none !important;


}

.page_item_cus>.navprev {
    background-color: #817f11 !important;
    border: 0;
    color: #fff !important;
    margin: 5px 10px;
    border-top-left-radius: 50px !important;
    border-bottom-left-radius: 50px !important;
    font-size: 12px;
}

.page_item_cus>.next {
    background-color: #817f11 !important;
    border: 0;
    color: #fff !important;
    border-top-right-radius: 50px !important;
    border-bottom-right-radius: 50px !important;
    margin: 5px 10px;
    font-size: 12px;
    margin-left: 3px !important;
}

.page-item .page_active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #423c02 !important;
    border-color: #423c02 !important;
    font-weight: 700;
}

.page-link:hover {
    z-index: 3;
    color: #fff;
    background-color: #423c02 !important;
    border-color: #423c02 !important;
    font-weight: 700;
}

.page-link:focus {
    z-index: 3;
    color: #fff;
    background-color: #423c02 !important;
    border-color: #423c02 !important;
    font-weight: 700;
}